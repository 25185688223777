import React from 'react';
import MaintananceLogo from './assets/img/maintenanceIcon.png';
import Logo from './assets/img/bluemovie-new.jpg';
const Maintenance = () => {
  return (
    <main className="content-maintenance">
      <article className="container-maintenance">
        <div className="row">
          <div className="col-sm-12">
            <h1>
              <img className="maintenanceIcon" src={MaintananceLogo} alt="Wartungsarbeiten" /> Wartungsarbeiten
            </h1>
            <h2>Wichtiger Hinweis für Blue Movie</h2>
            <p>
              Bitte beachten Sie, dass vom 25.01.2022, 08:00 Uhr bis 16:00 Uhr Wartungsarbeiten stattfinden. Deshalb
              können in dieser Zeit keine Filme bestellt oder abgerufen werden.
              <br />
              Wir bitten die Unannehmlichkeiten zu entschuldigen.
            </p>
            <img style={{ marginBottom: 4 }} className="maintenance-img" src={Logo} alt="Logo Bluemovie.net" />
            <p>
              Vielen Dank für Ihr Verständnis.
              <br />
              Ihr Blue Movie Team
            </p>
          </div>
        </div>
      </article>
    </main>
  );
};

export default Maintenance;
